import {reactive} from "vue";
import {shopBaseTimeList} from '@/utils/api/shop.js'
import resetMessage from '@/utils/tool/message';

export default function (callBack) {
    const comboList = reactive({})
    const getComboList = async (callBack) => {
        const {data, code, msg} = await shopBaseTimeList()
        if (code !== 0) {
            resetMessage.error(msg)
            return
        }
        Object.assign(comboList, data)
        if (typeof callBack === 'function') callBack(data)
    }
    getComboList(callBack).then()

    return {
        comboList,
        getComboList
    }
}