import {ref} from 'vue';
import route from '@/router/index.js';

const orderTypeConfig = {
    renew: '2',
    buy: '1'
}
export default function () {
    const type = ref(route.currentRoute.value.params.orderType)
    const titleInfo = {
        '1': {
            title: '购买套餐',
            subTitle: 'Purchase Package'
        },
        '2': {
            title: '续费',
            subTitle: 'renew'
        }
    }
    return {
        type,
        orderTypeConfig,
        titleInfo: titleInfo[type.value]
    }
}