<template>
  <div class="version-select">
    <div class="sub-desc mt_48 dp_f" v-if="type === orderTypeConfig.buy">
      <h2 class="sub-title h2">设备使用数量</h2>
      <Count v-model="countNumber" :min="1"></Count>
    </div>
    <div class="sub-desc mt_48" v-else>
      <h2 class="sub-title h2">当前设备使用数量：{{ baseInfo.seat }}</h2>
      <!--      <Count v-model="countNumber" :min="1"></Count>-->
    </div>
    <div class="sub-desc dp_f mt_52">
      <h2 class="sub-title h2">购买时长</h2>
      <div>
        <!--        <div class="desc">选择年付每年可节省￥<span>{{ $filters.toYuan(comboList.diff, '元', true) || 0 }}</span></div>-->
      </div>
    </div>
    <div class="line-content mt_16" v-if="comboList.list">
      <div class="model-title h2 dp_f">
        <div class="l">
          单台使用<strong>￥{{ $filters.toYuan(comboList.census, '商城', true) || 0 }}/月</strong>
        </div>
        <div class="r"></div>
      </div>
      <choose-line v-model="versionIndex" :line-list="comboList.list"></choose-line>
    </div>
    <el-form
        label-width="300"
        :rules="rules"
        ref="formRef"
        :model="formData"
        class="order-form large-input mt_48"
    >
      <el-form-item
          label="生效-到期时间"
      >
        <div class="value" style="font-weight: 500">{{ startTime }}</div>
      </el-form-item>
      <el-form-item
          label="优惠码："
          prop="code"
          class="mt_48"
      >
        <el-input
        v-elInput
            v-model="formData.code"
            type="text"
            class="large-input w360 code-input"
            placeholder="请输入优惠码"
            @blur="blurCode"
            style="position: relative;top: -6px;color:black"
        />
      </el-form-item>
    </el-form>
  </div>
</template>

<script setup>
import {computed, getCurrentInstance, reactive, ref, watch} from "vue";
import ChooseLine from "@views/shop/order/components/chooseLine/index.vue";
import {checkCoupon} from '@utils/api/shop.js'
import {useStore} from "vuex";
import moment from "moment";
import {useRoute} from "vue-router";
import Count from '@/views/shop/components/count.vue'
import useComboBase from "@utils/tool/shop/useComboBase";
import useOrderType from "@utils/tool/shop/useOrderType";
import useBaseInfo from "@utils/tool/shop/useBaseInfo";

const {baseInfo, setBaseInfo} = useBaseInfo()
const {type, orderTypeConfig} = useOrderType()
const countNumber = ref(1)
const {comboList} = useComboBase(() => {
  if (type.value !== orderTypeConfig.buy) {
    setBaseInfo((data) => {
      countNumber.value = data.seat
    })
  }
})

const route = useRoute()
const props = defineProps({
  code: {
    type: String,
    default: ''
  },
  modelValue: {
    type: Object,
    default: () => {
    }
  }
})
const store = useStore()

const emit = defineEmits(['update:code', 'update:modelValue'])
const versionIndex = ref(0)
const startTime = computed(() => {
  let timeValue = 0
  if (comboList.list) {
    timeValue = comboList.list[versionIndex.value].time
    emit('update:modelValue', {...comboList.list[versionIndex.value], countNumber: countNumber.value})
  }
  //time 往后一个月
  return baseInfo.value?.outTime ? baseInfo.value?.outTime + ' 至 ' + moment(baseInfo.value?.outTime).add(timeValue, 'months').format('YYYY-MM-DD') : moment().format('YYYY-MM-DD') + ' 至 ' + moment().add(timeValue, 'months').format('YYYY-MM-DD')
})
const tabChecked = ref(0)

watch(countNumber, (newValue, oldValue) => {
  const list = []
  if (+newValue === 0 || +oldValue === 0) {
    if (comboList?.list) {
      comboList.list.forEach(item => {
        list.push(reactive({
          color: item.color,
          sort: item.sort,
          time: item.time,
          nowPrice: item.nowPrice / (oldValue || 1),
          diffPrice: item.diffPrice / (oldValue || 1),
          oldPrice: item.oldPrice / (oldValue || 1),
          timeTitle: item.timeTitle
        }))
      })
    }
  } else {
    if (comboList?.list) {
      comboList.list.forEach(item => {
        list.push(reactive({
          color: item.color,
          sort: item.sort,
          time: item.time,
          nowPrice: item.nowPrice / (oldValue || 1) * newValue,
          diffPrice: item.diffPrice / (oldValue || 1) * newValue,
          oldPrice: item.oldPrice / (oldValue || 1) * newValue,
          timeTitle: item.timeTitle
        }))
      })
    }
  }
  Object.assign(comboList, {list: list})
  emit('update:modelValue', {...comboList.list[versionIndex.value], countNumber: countNumber.value})
})

const {proxy} = getCurrentInstance();
const formData = reactive({
  //优惠码
  code: '',
})
//自定义优惠码校验规则
const validateCode = (rule, value, callback) => {
  if (value !== '') {
    checkCoupon({discountCode: value}).then((res) => {
      if (res.code === 9176) {
        callback(new Error(res.msg))
      } else if (res.code !== 0) {
        proxy.$message.error(res.msg)
      } else {
        callback()
      }
    })
  } else {
    callback()
  }
}
const rules = reactive({
  code: [
    {validator: validateCode, trigger: 'blur'}
  ],
});
const blurCode = () => {
  emit('update:code', formData.code)
}
watch(() => props.code, (newVal) => {
  if (newVal !== formData.code) formData.code = newVal
})

</script>
<script>
import {defineComponent} from "vue";

export default defineComponent({
  name: "orderVersions",
});
</script>

<style lang="scss" scoped src="./index.scss"></style>
