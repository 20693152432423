<template>
  <div class="order-select container">
    <h2 class="title">产品购买</h2>
    <versions v-model:code="code" v-model="chooseItem"
    ></versions>
  </div>
</template>

<script setup>
import {getCurrentInstance, ref, watch} from "vue";
import Versions from "@views/shop/order/components/versions/index.vue";
import {useStore} from "vuex";

const store = useStore()

const {proxy} = getCurrentInstance();
const code = ref('')
const chooseItem = ref({
  comboId:''
})
defineExpose({
  code,
  chooseItem
})

//监听code,chooseItem 的变化
watch([() => code.value], () => {
  proxy.$emit('changeObj')
},)
watch(() => chooseItem.value, () => {
  proxy.$emit('changeObj')
}, {
  deep: true
})

</script>
<script>
import {defineComponent} from "vue";

export default defineComponent({
  name: "orderSelect",
});
</script>

<style lang="scss" scoped>
.order-select {
  padding: 60px 0;

  > .title {
    font-size: 30px;

  }
}
</style>
