<template>
  <div class="shop-order">
    <div class="order-head" :class="type === orderTypeConfig.buy?'':'renew'">
      <shop-header type="other"></shop-header>
      <page-title :title="titleInfo.title" :sub-title="titleInfo.subTitle"></page-title>
    </div>
    <order-select ref="selectRefs" @changeObj="change"></order-select>
    <match ref="matchRefs" @changeObj="change">
    </match>
    <buy-info class="mt_60 buy-info" ref="buyInfoRefs" @changeObj="change"></buy-info>
    <div class="footer">
      <confirm-order class="confirm-order" :total="totalPrice" @confirm="handleClick"></confirm-order>
    </div>
    <confirm-dialog :order-info="orderInfo" @close-confirm="closeOrder" :form-data="addressInfo" :total="totalPrice"
                    ref="confirmDiaRefs"/>
  </div>
</template>

<script setup>
import {computed, getCurrentInstance, reactive, ref} from "vue";
import ShopHeader from "@views/shop/components/shopHeader/index.vue";
import PageTitle from "@views/shop/components/pageTitle.vue";
import OrderSelect from "@views/shop/order/components/select/index.vue";
import Match from "@views/shop/order/components/match/index.vue";
import BuyInfo from "@views/shop/order/components/buyInfo/index.vue";
import ConfirmOrder from "@views/shop/order/components/confirmOrder.vue";
import ConfirmDialog from "@views/shop/order/components/confirmDialog/index.vue";
import {createShopOrder, getShopOrderPrice} from '@utils/api/shop.js';
import {useStore} from "vuex";
import {useRoute} from "vue-router";
import {listenBack} from "@views/shop/utils";
import {debounce} from 'lodash'
import useOrderType from "@utils/tool/shop/useOrderType";

const {type, orderTypeConfig, titleInfo} = useOrderType()
listenBack()
const route = useRoute();
const baseInfo = computed(() => store.state.baseic)
const confirmDiaRefs = ref(null)
const orderInfo = ref({})
const addressInfo = ref({})
const buyInfoRefs = ref(null)
const selectRefs = ref(null)
const matchRefs = ref(null)
const store = useStore()
const {proxy} = getCurrentInstance();
const totalPrice = reactive({
  total: 0,
  diff: 0
})

const change = debounce(() => {
  if (!selectRefs.value || !matchRefs.value || !buyInfoRefs.value) return
  let {chooseItem, code} = selectRefs.value
  let {tableData: list} = matchRefs.value
  let {formData} = buyInfoRefs.value
  if (formData.addressId) {
    addressInfo.value = formData
  }
  if (!chooseItem.time) return
  getShopOrderPrice({
    discountCode: code,
    contactUser: formData.name,
    contactMobile: formData.mobile,
    contactAddress: formData.address,
    time: chooseItem.time,
    list: list,
    seat: type.value === '1' ? chooseItem.countNumber : baseInfo.value.seat
  }).then(res => {
    if (res.code !== 0) proxy.$message.error(res.msg)
    totalPrice.total = proxy.$filters.toYuan(res.data.total, '商城')
    totalPrice.diff = proxy.$filters.toYuan(res.data.diff, '商城', true)
  })
}, 200)
const handleClick = () => {
  if (!selectRefs.value || !matchRefs.value || !buyInfoRefs.value) return
  let {formData} = buyInfoRefs.value
  let {chooseItem, code} = selectRefs.value
  let {tableData: list} = matchRefs.value
  if (!formData.addressId) {
    if (list.findIndex(item => item.count > 0) !== -1) {
      proxy.$message.error('请选择收货地址')
      return
    }
  }
  list = list.filter(item => item.count > 0)
  createShopOrder({
    discountCode: code,
    contactUser: formData.name,
    contactMobile: formData.mobile,
    contactAddress: formData.address,
    time: chooseItem.time,
    list: list,
    seat: type.value === '1' ? chooseItem.countNumber : ''
  }).then(res => {
    if (res.code !== 0) {
      proxy.$message.error(res.msg)
      return
    }
    orderInfo.value = res.data
    confirmDiaRefs.value.showDia = true
  })
}
const closeOrder = () => {

}
</script>
<script>
import {defineComponent} from "vue";

export default defineComponent({
  name: "shopOrder",
});
</script>

<style lang="scss" src="./index.scss" scoped>

</style>
