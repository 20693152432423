<template>
  <div class="choose-line">
    <div class="line-index" >
      <div class="line-content" ref="lineRefs">
        <div class="line-item cur_p" :class="[tabChecked === index ? 'active':'']" v-for="(item,index) in lineList"
             :key="index" @click="checkItem(index)">
          <div class="title">{{ item.timeTitle }}</div>
          <div class="year-sale one " v-if="item.color === 1 && tabChecked !== index">
            <div>
              仅{{ $filters.toYuan(item.nowPrice, '商城', true) }}元
            </div>
            <div>
              (优惠￥{{ $filters.toYuan(item.diffPrice, '商城', true) }})
            </div>
          </div>
          <div class="year-sale two" v-if="item.color === 2 && tabChecked !== index">
            <div>
              仅{{ $filters.toYuan(item.nowPrice, '商城', true) }}元
            </div>
            <div>
              (优惠￥{{ $filters.toYuan(item.diffPrice, '商城', true) }})
            </div>
          </div>
        </div>
        <div class="progress-bar" :style="positionWidth" :class="animate?'animate':''">
        </div>
        <div class="control cur_p popover" :class="animate?'animate':''" @mousedown="mouseDown" @mouseup="btnMouseup"
             :style="positionLeft" ref="btnRef" v-if="lineList[tabChecked]">
          <div class="popover-content price-pop">
            <div class="price">共<span class="num">{{
                $filters.toYuan(lineList[tabChecked].nowPrice, '商城', true)
              }}</span>元
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {computed, getCurrentInstance, onMounted, reactive, ref, toRefs, watch} from "vue";

const props = defineProps({
  lineList: {
    type: Array,
    default: () => [],
  },
  modelValue: {
    type: Number,
    default: 0
  }
})
const positionInfo = reactive({
  left: 0,
  btnLeft: 0,
  btnRef: null,
  offsetLeft: 0,
  diffWidth:0
})
const animate = ref(false)
const { btnRef } = toRefs(positionInfo);
const lineRefs = ref(null)
const {lineList} = toRefs(props)
const emit = defineEmits(['update:modelValue'])
const tabChecked = ref(0)
onMounted(() => {
  positionInfo.offsetLeft = lineRefs.value.offsetLeft
  //设置初始化长度
  positionInfo.left = lineRefs.value.offsetWidth * (1/lineList.value.length) / 2
  positionInfo.diffWidth = lineRefs.value.offsetWidth /lineList.value.length
  document.body.addEventListener("mouseup", btnMouseup)
})
const mouseDown = (e)=>{
  positionInfo.btnLeft = e.pageX - positionInfo.btnRef.offsetLeft
  document.body.addEventListener("mousemove", btnMousemove)
}
const  btnMousemove = (e) => {
  // left等于鼠标的位置pageX减去positionInfo.btnRef.offsetLeft再减去positionInfo.btnLeft（计算出的距离）
  let x = e.pageX - positionInfo.offsetLeft - positionInfo.btnLeft
  // 判断滑动图片时是否超出范围
  if (x <= 0) x = 0
  else if (x >= lineRefs.value.offsetWidth) x = lineRefs.value.offsetWidth
  positionInfo.left = x
  setTab(x)
}

function  setTab(x,adsorption = false){
  let tab = Math.ceil(x / positionInfo.diffWidth)
  if (tab < 1) {
    tab = 1
  } else if (tab > lineList.value.length) {
    tab = lineList.value.length
  }
  if(adsorption){
    positionInfo.left = (tab - 1 ) * positionInfo.diffWidth + (positionInfo.diffWidth * 0.5)
  }
  // if(tabChecked.value === tab -1  ) return
  tabChecked.value = tab  - 1
}

let timer = null
// 鼠标松开
const btnMouseup = () => {
    setTab( positionInfo.left ,true)
    animate.value = true
    if (timer) clearTimeout(timer)
    timer = setTimeout(() => {
      animate.value = false
    }, 150)
  document.body.removeEventListener("mousemove", btnMousemove)
}

const positionWidth = computed(() => {
  return{
    width: positionInfo.left + 'px'
  }
})
const positionLeft = computed(()=>{
  return{
    left: positionInfo.left - 10 + 'px'
  }
})
const checkItem = (index)=>{
  if(index === tabChecked.value) return
  positionInfo.left = positionInfo.diffWidth * (index ) + (positionInfo.diffWidth * 0.5)
  tabChecked.value = index
}
const isDrag = ref(false)

const {proxy} = getCurrentInstance();

watch(() => tabChecked.value, (val) => {
  emit('update:modelValue', val)
})
</script>
<script>
import {defineComponent} from "vue";

export default defineComponent({
  name: "chooseLine",
});
</script>

<style lang="scss" src="./index.scss" scoped>

</style>
